import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import React, { Fragment, useEffect, useState } from 'react';

import Card from 'components/molecules/Card';
import Table from 'components/organisms/Table/ReactTable/SyncTable';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import Routes from 'constants/Routes';
import { FileInformationBuilder, FileInformationProps, FormatFileInformation } from 'models/FileBuilder';
import FormatDate from 'utils/FormatDate';

const File = () => {
    const { id } = useParams<{ id: string }>();
    const [file, setFile] = useState<FileInformationProps>(FileInformationBuilder());

    const GetFileInfo = async () => {
        const { status, ...response } = await GET(API.FILE + id);
        if (!status) return;
        setFile(FormatFileInformation(response));
    };

    useEffect(() => {
        GetFileInfo();
    }, []);

    return (
        <section className="container mx-auto my-5 px-2 grid grid-cols-1 lg:grid-cols-5 gap-5">
            <Card info="En provenance de" className="lg:col-span-2 lg:col-start-1">
                <Link to={`${Routes.COMPANY}/${file.tiersId}`}>
                    <span className="text-sm w-5/6 text-purple-900 hover:underline">{file.tiersName}</span>
                </Link>
            </Card>
            <Card info={'Traiter le' + ' ' + FormatDate(new Date(file.date))} className="lg:col-start-3">
                {file.userName ? `Par ${file.userName}` : 'non identifié'}
            </Card>
            <Card info="Lu" className="lg:col-start-4">
                {file.fileDocumentRead}
            </Card>
            <Card info="Ajouté" className="lg:col-start-5">
                {file.fileDocumentAdd}
            </Card>
            <div className="lg:col-start-1 lg:row-start-2 col-span-5">
                <Table columns={column} data={file.informationsDocuments} />
            </div>
        </section>
    );
};

const DocumentCell = (props: {
    value: string;
    row: { original: { color: 'success' | 'secondary' | 'info' | 'warning' | 'none'; id: number } };
}) => (
    <Fragment>
        <Link to={`${Routes.DOCUMENTS}/${props.row.original.id}`}>
            <span className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</span>
        </Link>
    </Fragment>
);

const TiersCell = (props: { value: string; row: { original: { tiersDestId: number } } }) => (
    <Fragment>
        <Link to={`${Routes.COMPANY}/${props.row.original.tiersDestId}`}>
            <span className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</span>
        </Link>
    </Fragment>
);

const column = [
    {
        Header: 'Numéro de document',
        accessor: 'documentNumber',
        Cell: DocumentCell,
    },
    {
        Header: 'Tiers',
        accessor: 'tiersDestName',
        Cell: TiersCell,
    },
    {
        Header: 'Action',
        accessor: 'action',
    },
    {
        Header: 'Nb Colis',
        accessor: 'countPackage',
    },
];

export default File;
