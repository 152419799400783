import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Pages from 'pages';
import Header from 'pages/Headers';
import LoginPage from 'pages/Login';

import UrlRoutes from 'constants/Routes';
import { UserControl } from 'utils/UserControl';
import { Notification } from 'contexts/Notifications';
import { AuthenticationProvider, useAuthenticateContext } from 'contexts/Authentication';

const App = () => {
    const { authentication } = useAuthenticateContext();
    return (
        <main className="flex flex-col min-h-screen h-full bg-gray-50">
            <BrowserRouter basename="/">
                <AuthenticationProvider>
                    <Notification>
                        <Header />
                        <Routes>
                            <Route path={UrlRoutes.LOGIN} element={<LoginPage />} />
                            {Pages.map((page, index) => {
                                if (UserControl(authentication.roleLabel, page.roles) === false) return;
                                return <Route key={index} path={page.path} element={<page.component />} />;
                            })}
                        </Routes>
                    </Notification>
                </AuthenticationProvider>
            </BrowserRouter>
        </main>
    );
};

export default App;
