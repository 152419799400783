/* eslint-disable @typescript-eslint/no-explicit-any */
interface FileDocumentList {
    action: string;
    id: number;
    countPackage: number;
    documentNumber: string;
    tiersDestId: number;
    tiersDestName: string;
    [key: string]: string | number;
}

export interface FileInformationProps {
    tiersName: string;
    userName: string;
    date: string;
    tiersId: number;
    userId: number;
    fileDocumentAdd: number;
    fileDocumentRead: number;
    informationsDocuments: FileDocumentList[];
}

export const FileInformationBuilder = (values: Partial<FileInformationProps> = {}): FileInformationProps => {
    return {
        tiersName: '',
        userName: '',
        date: '',
        tiersId: 0,
        userId: 0,
        fileDocumentAdd: 0,
        fileDocumentRead: 0,
        informationsDocuments: [],
        ...values,
    };
};

export const FormatFileInformation = ({ data }: any): FileInformationProps => {
    const _documents = data[0].informations.documents.map(
        (data: any): FileDocumentList => {
            return {
                action: data.action,
                id: data.id,
                countPackage: data.nombreColis,
                documentNumber: data.numeroDocument,
                tiersDestId: data.tiersDestination.id,
                tiersDestName: data.tiersDestination.raisonSociale,
            };
        },
    );

    return {
        tiersName: data[0].RaisonSocialeTiers,
        userName: data[0].concatNomPrenom,
        date: data[0].dateTraitement,
        tiersId: data[0].fk_tiers,
        userId: data[0].fk_utilisateur,
        fileDocumentAdd: data[0].informations.nombreDocuments.ajoute,
        fileDocumentRead: data[0].informations.nombreDocuments.lu,
        informationsDocuments: _documents,
    };
};
